import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header
  let bgClass = ""

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <StaticImage
          layout="fixed"
          className="logo circle"
          formats={["auto", "webp", "avif"]}
          src="../images/icon-512x512.png"
          width={80}
          height={80}
          quality={95}
          alt="Profile picture"
        />
        <Link to="/">{title} ⚡️</Link>
      </h1>
    )
    bgClass = "bg"
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className={bgClass}>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <div className="wrapper">
          <header className="global-header">{header}</header>
          <main>{children}</main>
        </div>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer>
      </div>
    </div>
  )
}

export default Layout
